<template>
    <div class="ui-page">
        <div class="login-title">登录</div>
        <van-form @submit="onSubmit" autocomplete="off">
            <van-field
                v-model="dataForm.username"
                name="用户名"
                label="用户名"
                placeholder="用户名"
                :rules="[{ required: false, message: '请填写用户名' }]"
            />
            <van-field
                v-model="dataForm.password"
                type="password"
                name="密码"
                label="密码"
                placeholder="密码"
                :rules="[{ required: false, message: '请填写密码' }]"
            />
            <div style="margin: 16px;">
                <van-button round block :loading="saving" type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            saving: false,
            dataForm: {
                username: '',
                password: ''
            }
        }
    },
    methods: {
        onSubmit() {
            if(!this.dataForm.username) {
                this.$dialog.alert({
                    message: '账号不能为空'
                })
                return false
            }
            if(!this.dataForm.password) {
                this.$dialog.alert({
                    message: '密码不能为空'
                })
                return false
            }

            this.$store.commit('login', {
                userId: 1,
                token: '9sd8fa6s7f6',
                refreshToken: '9sd8fa6s7f6',
                expireTime: 600000
            })

            let path = '/'
            if(this.$route.params.redirectPath) {
                path = this.$route.params.redirectPath
            }
            this.$router.push(path)

            // this.saving = true
            // this.$axios.post('/api/wechatOfficial/v1/auth/login', {
            //     username: this.dataForm.username,
            //     password: this.dataForm.password
            // }).then(res => {
            //     this.$store.commit('login', {
            //         userId: res.data.userId,
            //         token: res.data.token,
            //         refreshToken: res.data.refreshToken,
            //         expireTime: res.data.expireTime * 1000 + new Date().getTime()
            //     })
            //     this.$router.push(path)
            // }).catch(err => {
            //     this.saving = false
            //     this.$dialog.alert({
            //         message: err.message,
            //     })
            // })

        }
    }
}
</script>

<style scoped>
.login-title {
    padding: 60px 16px 16px;
    font-size: 20px;
}
</style>